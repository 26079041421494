/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { FormHelperText, Grid, makeStyles, Typography, FormControl, Link, TextField, OutlinedInput } from '@material-ui/core';

import Alert from 'src/components/alert';
import HelpWidget from 'src/components/helpWidget';
import Checkbox from 'src/components/checkbox';
import Paper from 'src/components/paper';
import Switch from 'src/components/switch';
import TooltipedText from 'src/components/tooltipedText';

import pages from 'src/features/me/pages';

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  switch: {
    marginLeft: 20,
  },
  label: {
    opacity: 0.5,
  },
  subtitle: {
    fontSize: 12,
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  cardSm: {
    '&.MuiPaper-root': {
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(0.5)}px ${theme.spacing(4)}px`,
    },
    minHeight: 124,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
}));

export default function ({ data, isLoading, updateConfiguration }) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      enable_call_recording: data.enable_call_recording,
      ivr_announce: data.ivr_announce,
      concurrent_calls: data.concurrent_calls,
    },
    validationSchema: yup.object().shape({
      enable_call_recording: yup.boolean().required(),
      concurrent_calls:
        yup.string()
          .matches(/^\d*$/, 'Only digits'),
      ivr_announce: yup.array().of(
        yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(),
          enabled: yup.boolean().required(),
        }),
      ),
    }),
    onSubmit: (values) => {
      updateConfiguration({
        ...data,
        enable_call_recording: values.enable_call_recording == true ? 1 : 0,
        ivr_announce: values.ivr_announce,
        concurrent_calls: values.concurrent_calls,
      });
    }
  });

  const [popup, setPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleClosePopup = () => {
    setAnchorEl(null);
    setPopup(!popup);
  };

  useEffect(() => {
    setPopup(false);
  }, []);

  useEffect(() => {
    const helpPage = pages.find((item) => {
      return item.name === 'Help and Support';
    });
    setPage(helpPage);
  }, [pages]);

  const handleChangeEnableCallRecording = (event, value) => {
    formik.setFieldValue('enable_call_recording', value, false);
    formik.submitForm();
  };

  const handleChangeInvAnnounce = (event, value, index) => {
    formik.setFieldValue(`ivr_announce[${index}].enabled`, value, false);
    formik.submitForm();
  };

  const debounce = (f, ms) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null)
    }

    setTimer(setTimeout(() => {
      f.apply(this, arguments)
    }, ms));

  }

  const handleChangeConcurrentCalls = (event) => {
    const { value } = event.target;

    formik.setFieldValue('concurrent_calls', value, true);

    if (value == 0) {
      setShowHint(true);
    } else {
      setShowHint(false);
    }

    if (formik.isValid) {
      debounce(formik.submitForm, 1000)
    }
  };

  useEffect(() => {
    formik.setValues(data);
    setShowHint(data.concurrent_calls == 0);
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>

        <Grid item>
          <Paper className={classes.card}>
            <div role="presentation" className={classes.header}>
              <div role="presentation" className={classes.inlineContainer}>
                <TooltipedText tooltipText="Make sure you stay compliant with recording regulations in your region">
                  <Typography variant="h6">Enable Call Recording</Typography>
                </TooltipedText>

                <Switch
                  className={classes.switch}
                  checked={!!formik.values.enable_call_recording}
                  onChange={handleChangeEnableCallRecording}
                />
              </div>
            </div>

            <FormControl margin="normal">
              <div role="presentation" className={classes.inlineContainer}>
                <TooltipedText tooltipText="Coming soon - Generate concise summaries of all connected calls">
                  <Typography variant="body2" className={classes.label}>
                    Call notes
                  </Typography>
                </TooltipedText>

                <Switch className={classes.switch} disabled />
              </div>
            </FormControl>

            <FormControl margin="normal">
              {page && (page.popupList.length > 0) &&
                <>
                  <Typography>
                    To learn more about Call notes, contact <Link style={{ cursor: 'pointer' }} onClick={(event) => {
                      setPopup(!popup);
                      setAnchorEl(event.currentTarget);
                    }}><b>customer success</b></Link>
                  </Typography>
                </>}
            </FormControl>
          </Paper>
        </Grid>


        <Grid item>
          <Paper className={classes.cardSm}>
            <div role="presentation" className={classes.inlineContainer}>
              <TooltipedText tooltipText="Overrides all Group Concurrent Call settings">
                <Typography variant="h6">Concurrent Calls</Typography>
              </TooltipedText>
            </div>
            <FormControl margin="normal">
              <Grid item xs={8}>
                <TextField
                  name="concurrent_calls"
                  onChange={handleChangeConcurrentCalls}
                  value={formik.values.concurrent_calls}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.concurrent_calls)}
                  placeholder='0'
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fullWidth
                />
                <FormHelperText component="div">
                  {formik.errors.concurrent_calls && formik.touched.concurrent_calls ? (
                    <span role="alert" style={{ color: 'red' }}>
                      {formik.errors.concurrent_calls}
                    </span>
                  ) : null}
                </FormHelperText>
                <FormHelperText component="div">
                  {showHint ? (
                    <span role="alert">
                      0 for No Limit
                    </span>
                  ) : null}
                </FormHelperText>
              </Grid>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.card}>
          <div role="presentation" className={classes.header}>
            <div role="presentation" className={classes.inlineContainer}>
              <TooltipedText tooltipText="Optional lead info for reps delivered via text to speech">
                <Typography key={`tooltipText_text`} variant="h6">Select Fields for IVR to Announce</Typography>
              </TooltipedText>
            </div>
          </div>

          <FormControl margin="dense">
            {
              formik.values.ivr_announce.length > 0 && (formik.values.ivr_announce.map((ivr_announce_item, index) => {
                switch (ivr_announce_item.name) {
                  case 'Parameter 1':
                    return (
                      <TooltipedText tooltipText="Announces name of Parameter">
                        <Checkbox
                          capitalize
                          key={`ivr_announce_${index}`}
                          label={ivr_announce_item.name}
                          checked={ivr_announce_item.enabled}
                          onChange={(event, value) => handleChangeInvAnnounce(event, value, index)}
                        />
                      </TooltipedText>
                    )
                  default:
                    return (
                      <Checkbox
                        capitalize
                        key={`ivr_announce_${index}`}
                        label={ivr_announce_item.name}
                        checked={ivr_announce_item.enabled}
                        onChange={(event, value) => handleChangeInvAnnounce(event, value, index)}
                      />
                    )
                }
              }))
            }
          </FormControl>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.card}>
          <div role="presentation" className={classes.header}>
            <div role="presentation" className={classes.inlineContainer}>
              <Typography variant="h6">Caller ID’s</Typography>
            </div>
          </div>

          <FormControl margin="normal">
            <Typography variant="body1" paragraph>
              To reps: <b>{isNaN(data.caller_id_for_reps) ? data.caller_id_for_reps : `+${data.caller_id_for_reps}`}</b>
            </Typography>

            <Typography variant="body1" paragraph>
              To leads: <b>{isNaN(data.caller_id_for_leads) ? data.caller_id_for_leads : `${data.caller_id_for_leads}`}</b>
            </Typography>
          </FormControl>

          {page && (page.popupList.length > 0) &&
            <>
              <Typography>
                <Link style={{ cursor: 'pointer' }} onClick={(event) => {
                  setPopup(!popup);
                  setAnchorEl(event.currentTarget);
                }}>
                  <b>Contact customer success</b>
                </Link>{' '}
                to change outbound caller id
              </Typography>
              <HelpWidget
                isOpen={popup}
                anchorEl={anchorEl}
                popupList={page.popupList}
                handleClosePopup={handleClosePopup}
              ></HelpWidget>
            </>}
        </Paper>
      </Grid>
    </Grid>
  );
}
