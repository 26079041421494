import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Checkbox } from '@material-ui/core';

import Tooltip from 'src/components/tooltip';

import TooltipIcon from 'src/assets/icons/tooltip.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    border: '1px solid gray',
    padding: '8px 16px 8px 8px',
    'border-radius': '8px',
    alignItems: 'center',
    marginTop: '-8px',
    'min-width': '210px',
    // '& > span ': {
    //   marginTop: '-22px',
    // }
  },
  icon: {
    paddingLeft: 9,
    display: 'flex',
  },
  textTransformCapitalize: {
    textTransform: 'capitalize',
  },
}));

export default function CustomCheckbox({ id, label, tooltipText, checked, onChange, disableCheckbox, capitalize = false, label2, border }) {
  const classes = useStyles();

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={classes.root}>
      <div className={border ? classes.wrapper : classes.root}>

        <Checkbox
          name={id}
          checked={checked || isChecked}
          onChange={onChange || handleChange}
          disabled={disableCheckbox || false}
        />

        <div>
          <Typography
            className={capitalize === true ? classes.textTransformCapitalize : ''}
          >
            {label}
          </Typography>
          <Typography
            className={capitalize === true ? classes.textTransformCapitalize : ''}
          >
            {label2}
          </Typography>
        </div>

        {
          tooltipText && (
            <Tooltip title={tooltipText} arrow>
              <div className={classes.icon}>
                <TooltipIcon />
              </div>
            </Tooltip>
          )
        }
      </div>
    </div>
  );
}

CustomCheckbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  onChange: PropTypes.func,
};

CustomCheckbox.defaultProps = {
  id: 'checkbox',
  checked: false,
  disableCheckbox: false,
  tooltipText: false,

  onChange: () => { },
};
