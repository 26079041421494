import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from './slice';

export const useAdvancedActions = () => {
  const dispatch = useDispatch();

  const updateConfiguration = useCallback(configuration => dispatch(actions.updateConfiguration(configuration)), [dispatch]);
  const searchConfiguration = useCallback(configuration => dispatch(actions.searchConfiguration(configuration)), [dispatch]);
  const deletePhoneData = useCallback(({ id, search }) => dispatch(actions.deletePhoneData({ id, search })), [dispatch]);

  return {
    updateConfiguration,
    searchConfiguration,
    deletePhoneData,
  };
};

export const useFetchAdvanced = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getConfiguration());
  }, [dispatch]);
};

export const useFetchAdvancedSettingsSearch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAdvancedSettingsSearch());
  }, [dispatch]);
};
