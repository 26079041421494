import clsx from 'clsx';
import { format, isWithinInterval } from 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { convertDateTimeLocalFormatToUTC } from 'src/helpers/time';

import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Divider, Grid, Typography, Button, Radio, IconButton, TextField } from '@material-ui/core';
import { KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import DeleteIcon from 'src/assets/icons/close.svg';
import EditIconAlt from 'src/assets/icons/edit.svg';

import Checkbox from 'src/components/checkbox';

const useStyles = makeStyles(() => ({
  dateInterval: {
    backgroundColor: 'rgba(42, 151, 183, 0.3)',
    borderRadius: '50%',
    // margin: '0 1px',
    '& .MuiPickersDay-day .MuiTypography-root': {
      // color: '#fff',
      fontWeight: '700 !important',
    },
  },
  root: {
    marginBottom: 10,
  },
  daysRow: {
    display: 'flex',
    backgroundColor: '#f6f6f6',
    flexDirection: 'column',
  },
  daysRowEdit: {
    minHeight: 51,
    alignItems: 'center',
    padding: '0 12px',
  },
  daysRowEditDate: {
    width: 375,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  daysRowEditRecurring: {
    paddingLeft: 284,
  },
  daysRowDateWrap: {
    flexDirection: 'column',
    background: '#fff',
    margin: 12,
    width: 'calc(100% - 24px)',
  },
  daysRowDate: {
    display: 'flex',
  },
  daysRowDateButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 12px 12px 0',
  },
  contentRow: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12,
  },
  checkboxWrapper: {
    width: '100%',
    marginLeft: 30,
  },
  textField: {
    maxWidth: 170,
  },
  blackoutDaysDate: {
    width: 375,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const dateFormat = date => format(date, 'dd MMM yyyy');

const dateFormatToSave = date => format(date, 'yyyy-MM-dd HH:mm:ss');

export default function BlackoutDaysRow({ index, day, handleEditBlackoutDay, handleDeleteBlackoutDay }) {
  const classes = useStyles();

  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      start: new Date(),
      end: new Date(),
      recurring: false,
    },
    validationSchema: yup.object().shape({
      start: yup.date().required(),
      end: yup.date().required(),
      recurring: yup.boolean().required(),
    }),
    onSubmit: (values) => {
      handleEditBlackoutDay({ index, values });
    },
  });

  const handelEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);

    formik.setValues({
      start: new Date(day.from),
      end: new Date(day.to),
      recurring: day.is_recurrent == true,
    });
  };

  const handelSave = () => {
    setEdit(false);

    formik.submitForm();
  };

  const handleDelete = (event, value) => {
    handleDeleteBlackoutDay(index);
  };

  const handleSetNewBlackoutDayStart = calendarDate => {
    formik.setFieldValue('start', calendarDate, false);
  };

  const handleSetNewBlackoutDayEnd = calendarDate => {
    formik.setFieldValue('end', calendarDate, false);
  };

  const renderWrappedWeekDay = (calendarDate, selectedDate, dayInCurrentMonth, Element) => {
    const dayIsBetween = isWithinInterval(calendarDate, { start: formik.values.start, end: formik.values.end });

    return dayIsBetween ? (
      <div className={classes.dateIntervaleWrapper}>
        <div className={clsx(dayInCurrentMonth && classes.dateInterval)}>{Element}</div>
      </div>
    ) : (
      Element
    );
  };

  useEffect(() => {
    formik.setValues({
      start: new Date(day.from),
      end: new Date(day.to),
      recurring: day.is_recurrent == true,
    });
  }, [day]);

  return (
    <div className={classes.root}>
      <div className={classes.daysRow}>

        {
          edit === false
            ? (<div className={classes.contentRow}>
              <div className={classes.blackoutDaysDate}>
                <Typography>Start Date {dateFormat(new Date(convertDateTimeLocalFormatToUTC(formik.values.start.toString())))}</Typography>

                <ArrowForwardIcon color="primary" fontSize="small" />

                <Typography>End Date {dateFormat(new Date(convertDateTimeLocalFormatToUTC(formik.values.end.toString())))}</Typography>
              </div>

              <Checkbox
                label="Recurring"
                checked={formik.values.recurring}
                disableCheckbox
              />

              <div>
                <IconButton onClick={handelEdit}>
                  <EditIconAlt />
                </IconButton>

                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>)
            : (<>
              <Grid container className={classes.daysRowEdit}>
                <Grid item className={classes.daysRowEditDate}>

                  <Typography>Start Date {dateFormat(new Date(convertDateTimeLocalFormatToUTC(formik.values.start.toString())))}</Typography>

                  <ArrowForwardIcon color="primary" fontSize="small" />

                  <Typography>End Date {dateFormat(new Date(convertDateTimeLocalFormatToUTC(formik.values.end.toString())))}</Typography>

                </Grid>

                <Grid item className={classes.daysRowEditRecurring}>
                  <Checkbox
                    id="recurring"
                    name="recurring"
                    label="Recurring"
                    checked={formik.values.recurring}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container className={classes.daysRowDateWrap}>
                <Grid item className={classes.daysRowDate}>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={formik.values.start}
                        maxDate={formik.values.end}
                        onChange={handleSetNewBlackoutDayStart}
                        renderDay={renderWrappedWeekDay}
                        variant="static"
                        openTo="date"
                        disableToolbar
                        fullWidth
                        orientation="portrait"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={formik.values.end}
                        minDate={formik.values.start}
                        onChange={handleSetNewBlackoutDayEnd}
                        renderDay={renderWrappedWeekDay}
                        variant="static"
                        openTo="date"
                        disableToolbar
                        fullWidth
                        orientation="portrait"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid item className={classes.daysRowDateButtons}>
                  <Grid item>
                    <Button
                      color="secondary"
                      size="large"
                      disableElevation
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      onClick={handelSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>)
        }
      </div>
    </div>
  );
}
