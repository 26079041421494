/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { format, isWithinInterval } from 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';

import DateFnsUtils from '@date-io/date-fns';
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { convertDateTimeLocalFormatToUTC } from 'src/helpers/time';

import Checkbox from 'src/components/checkbox';
import Paper from 'src/components/paper';
import Switch from 'src/components/switch';
import TooltipedText from 'src/components/tooltipedText';

import BlackoutDaysRow from './blackoutDayRow';

import { setDay, setNewDay, deleteDay } from './slice';

const useStyles = makeStyles(theme => ({
  dateInterval: {
    backgroundColor: 'rgba(42, 151, 183, 0.3)',
    borderRadius: '50%',
    // margin: '0 1px',
    '& .MuiPickersDay-day .MuiTypography-root': {
      // color: '#fff',
      fontWeight: '700 !important',
    },
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    '& > *:first-child': {
      marginRight: 10,
    },
    justifyContent: 'space-between',
  },
  inlineContainerSwitcher: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    minHeight: theme.spacing(3),
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    width: 85,
    marginRight: 10,
  },
  select: {
    width: 130,
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  switch: {
    marginLeft: 20,
  },
  calender: {
    padding: theme.spacing(3, 0),
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      [theme.breakpoints.up('md')]: {
        zoom: 1.2,
      },
    },
  },
  divider: {
    margin: theme.spacing(5, 0),
  },
  daysRow: {
    display: 'flex',
    backgroundColor: '#f6f6f6',
    flexDirection: 'column',
    width: '100%',
  },
  daysRowEdit: {
    padding: '0 12px',
  },
  daysRowEditDate: {
    width: 375,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  daysRowEditRecurring: {
    paddingLeft: 284,
  },
  daysRowDateWrap: {
    flexDirection: 'column',
    background: '#fff',
    margin: 12,
    width: 'calc(100% - 24px)',
  },
  daysRowDate: {
    display: 'flex',
  },
  daysRowDateButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 12px 12px 0',
  },
  dialogContent: {
    margin: 0,
  }
}));

const dateFormatToShow = date => format(date, 'dd MMM yyyy');

const dateFormatToSave = date => format(date, 'yyyy-MM-dd');

const BlackoutDays = ({ days, data, updateConfiguration }) => {
  const classes = useStyles();

  const [isNew, setIsNew] = useState(false);

  const [open, setOpen] = useState(false);

  const [date, setDate] = useState(new Date());

  const [deleteIndex, setDeleteIndex] = useState(null);

  const formikDisableParking = useFormik({
    initialValues: {
      disableParking: false,
    },
    validationSchema: yup.object().shape({
      disableParking: yup.boolean(),
    }),
  });

  const formikBlackoutDays = useFormik({
    initialValues: {
      list: [],
    },
    validationSchema: yup.object().shape({
      list: yup.array().of(
        yup.object().shape({
          from: yup.string().required(),
          to: yup.string().required(),
          is_recurrent: yup.boolean().required(),
        }),
      ),
    }),
  });

  const formik = useFormik({
    initialValues: {
      start: new Date(),
      end: new Date(),
      recurring: true,
    },
    validationSchema: yup.object().shape({
      start: yup.date().required(),
      end: yup.date().required(),
      recurring: yup.boolean().required(),
    }),
    onSubmit: (values) => {
      const request = JSON.parse(JSON.stringify(data));

      request.blackout_days.push({
        from: dateFormatToSave(values.start),
        to: dateFormatToSave(values.end),
        is_recurrent: values.recurring == true ? 1 : 0,
      });

      setIsNew(!isNew);

      formik.setFieldValue('start', new Date(), false);
      formik.setFieldValue('end', new Date(), false);
      formik.setFieldValue('recurring', true, false);

      updateConfiguration(request);
    },
  });

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setDeleteIndex(null);
    setOpen(false);
  };

  const handleApplyDialog = () => {
    const request = JSON.parse(JSON.stringify(data));

    const blackoutDaysList = JSON.parse(JSON.stringify(formikBlackoutDays.values.list));

    if (deleteIndex === null) {
      return;
    }

    blackoutDaysList.splice(deleteIndex, 1)

    request.blackout_days = blackoutDaysList.map((day, index) => (
      {
        from: day.from,
        to: day.to,
        is_recurrent: day.is_recurrent,
      }
    ));

    formikBlackoutDays.setFieldValue('list', blackoutDaysList, false);

    setOpen(false);

    updateConfiguration(request);
  };

  const handleChangeIsNew = () => {
    setIsNew(!isNew);
  };

  const handleCancelNewBlackoutDay = () => {
    formik.setFieldValue('start', new Date(), false);
    formik.setFieldValue('end', new Date(), false);
    formik.setFieldValue('recurring', true, false);

    setIsNew(!isNew);
  };

  const handleSaveNewBlackoutDay = () => {
    formik.submitForm();
  };

  const handleSetNewBlackoutDayStart = calendarDate => {
    formik.setFieldValue('start', calendarDate, false);
  };

  const handleSetNewBlackoutDayEnd = calendarDate => {
    formik.setFieldValue('end', calendarDate, false);
  };

  const handleEditBlackoutDay = ({ index, values }) => {
    const request = JSON.parse(JSON.stringify(data));

    request.blackout_days[index] = {
      from: dateFormatToSave(values.start),
      to: dateFormatToSave(values.end),
      is_recurrent: values.recurring == true ? 1 : 0,
    };

    updateConfiguration(request);
  };

  const handleDeleteBlackoutDay = (index) => {
    setDeleteIndex(index);

    handleOpenDialog();
  };

  const handleChangeDisableParking = (event, value) => {
    const request = JSON.parse(JSON.stringify(data));

    formikDisableParking.setFieldValue('disableParking', value == true);

    request.disable_parking_arrived_on_blackout_day = value == true ? 1 : 0;

    updateConfiguration(request);
  };

  const renderWrappedWeekDay = (calendarDate, selectedDate, dayInCurrentMonth, Element) => {
    const dayIsBetween = isWithinInterval(calendarDate, { start: formik.values.start, end: formik.values.end });

    return dayIsBetween ? (
      <div role="presentation" className={classes.dateIntervaleWrapper}>
        <div role="presentation" className={clsx(dayInCurrentMonth && classes.dateInterval)}>{Element}</div>
      </div>
    ) : (
      Element
    );
  };

  useEffect(() => {
    const blackoutDaysList = [];

    data.blackout_days.forEach((blackoutDay, index) => {
      blackoutDaysList.push({
        ...blackoutDay,
        id: index,
      })
    });

    formikDisableParking.setFieldValue('disableParking', data.disable_parking_arrived_on_blackout_day == true);
    formikBlackoutDays.setFieldValue('list', blackoutDaysList, false);
  }, [data]);

  return (
    <Paper>
      <div role="presentation" className={classes.inlineContainer}>
        <TooltipedText tooltipText="Preset which holidays you want turn off the service">
          <Typography variant="h6">Blackout Days</Typography>
        </TooltipedText>

        <div role="presentation" className={classes.inlineContainerSwitcher}>
          <Typography variant="body1">Disable parking of inquires that arrive on a blackout date</Typography>

          <Switch
            className={classes.switch}
            checked={formikDisableParking.values.disableParking}
            onChange={handleChangeDisableParking}
          />
        </div>
      </div>

      <Grid container>
        {
          // eslint-disable-next-line no-nested-ternary
          formikBlackoutDays.values.list && formikBlackoutDays.values.list.length > 0
            ? (<Grid item xs={12}>
              {formikBlackoutDays.values.list.map((day, index) => (
                <BlackoutDaysRow
                  key={`blackout_days_${day.id}`}
                  index={index}
                  day={day}
                  handleEditBlackoutDay={handleEditBlackoutDay}
                  handleDeleteBlackoutDay={handleDeleteBlackoutDay}
                />
              ))}
            </Grid>)
            : (isNew === false
              ? (<Typography variant="body1">
                You have not loaded any blackout dates. Add one now ...
              </Typography>)
              : (<></>)
            )
        }
      </Grid>

      <Grid container direction="row">
        {
          isNew === false
            ? (<Button color="primary" onClick={handleChangeIsNew}>
              + Add blackout dates
            </Button>)
            : (<div role="presentation" className={classes.daysRow}>

              <Grid container className={classes.daysRowEdit}>
                <Grid item className={classes.daysRowEditDate}>

                  <Typography>Start Date {dateFormatToShow(new Date(convertDateTimeLocalFormatToUTC(formik.values.start.toString())))}</Typography>

                  <ArrowForwardIcon color="primary" fontSize="small" />

                  <Typography>End Date {dateFormatToShow(new Date(convertDateTimeLocalFormatToUTC(formik.values.end.toString())))}</Typography>
                </Grid>

                <Grid item className={classes.daysRowEditRecurring}>
                  <Checkbox
                    id="recurring"
                    name="recurring"
                    label="Recurring"
                    checked={formik.values.recurring}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container className={classes.daysRowDateWrap}>
                <Grid item className={classes.daysRowDate}>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={formik.values.start}
                        maxDate={formik.values.end}
                        onChange={handleSetNewBlackoutDayStart}
                        renderDay={renderWrappedWeekDay}
                        variant="static"
                        openTo="date"
                        disableToolbar
                        fullWidth
                        orientation="portrait"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={formik.values.end}
                        minDate={formik.values.start}
                        onChange={handleSetNewBlackoutDayEnd}
                        renderDay={renderWrappedWeekDay}
                        variant="static"
                        openTo="date"
                        disableToolbar
                        fullWidth
                        orientation="portrait"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid item className={classes.daysRowDateButtons}>
                  <Grid item>
                    <Button
                      color="primary"
                      size="large"
                      disableElevation
                      onClick={handleCancelNewBlackoutDay}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      onClick={handleSaveNewBlackoutDay}
                    // disabled={!formik.isValid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>)
        }
      </Grid>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are sure you whant to delete this blackout day settings?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            size="large"
            disableElevation
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            onClick={handleApplyDialog}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const mapStateToProps = ({ me }) => {
  const { advanceSettings } = me;

  const { blackoutDays } = advanceSettings;

  return { ...blackoutDays };
};

const mapDispatchToProps = { setDay, setNewDay, deleteDay };

export default connect(mapStateToProps, mapDispatchToProps)(BlackoutDays);
