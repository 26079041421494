import React from 'react';
import { useSelector } from 'react-redux';

import SEO from 'src/components/seo';

import CallSettings from 'src/features/advanceSettings/callSettings';
import DataRetention from 'src/features/advanceSettings/dataRetention';
import DuplicatesCheck from 'src/features/advanceSettings/duplicatesCheck';
import BlackoutDays from 'src/features/advanceSettings/blackoutDays';

import { getConfiguration, getLoading } from 'src/features/advanceSettings/state/selectors';
import { useAdvancedActions, useFetchAdvanced, useFetchAdvancedSettingsSearch } from 'src/features/advanceSettings/state/hooks'

export default function AdvanceSettingsPage() {
  const data = useSelector(getConfiguration);

  const isLoading = useSelector(getLoading);

  const { updateConfiguration } = useAdvancedActions();

  useFetchAdvanced();

  return (
    <>
      <SEO title="Settings | Advance Settings" />

      <CallSettings
        data={data}
        updateConfiguration={updateConfiguration}
        isLoading={isLoading}
      />

      <DuplicatesCheck
        data={data}
        updateConfiguration={updateConfiguration}
        useFetchAdvancedSettingsSearch={useFetchAdvancedSettingsSearch}
        isLoading={isLoading}
      />

      <BlackoutDays
        data={data}
        updateConfiguration={updateConfiguration}
        isLoading={isLoading}
      />

      <DataRetention
        data={data}
        updateConfiguration={updateConfiguration}
        useFetchAdvancedSettingsSearch={useFetchAdvancedSettingsSearch}
        isLoading={isLoading}
      />
    </>
  );
}
