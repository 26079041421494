/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import Switch from 'src/components/switch';
import Paper from 'src/components/paper';
import TooltipedText from 'src/components/tooltipedText';
import theme from 'src/themes/defaultTheme';

import SearchIcon from '@material-ui/icons/Search';
import CloseIconAlt from 'src/assets/icons/close.svg';

import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

import { getConfigurationSearch } from 'src/features/advanceSettings/state/selectors';
import { useAdvancedActions } from 'src/features/advanceSettings/state/hooks';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
  },
  inlineContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 12,
    '& > *:first-child': {
      marginRight: 10,
    },
  },
  toolbar: {
    minHeight: theme.spacing(3),
  },
  form: {
    paddingTop: 8,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    width: 85,
    marginRight: 10,
    '& input': {
      textAlign: 'center',
    },
  },
  select: {
    width: 130,
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowHidden: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    pointerEvents: 'none',
    opacity: 0.4,
  },
  closeButton: {
    marginRight: -12,
  },
  closeButtonHidden: {
    marginRight: -12,
    opacity: 0,
  },
  phoneWidth: {
    minWidth: 160,
  },
  buttonDone: {
    padding: '8px 8px',
  },
  labelDataRetension: {
    paddingRight: 8,
  },
  conteiner: {
    marginLeft: 0,
  },
  noResults: {
    paddingTop: 8,
  }
}));

export default function DuplicatesCheck({ data, isLoading, updateConfiguration }) {
  const classes = useStyles();

  const [isSubmited, setIsSubmited] = useState(0);

  const { phone, isLoading: isLoadingSearch, isEmpty } = useSelector(getConfigurationSearch);

  const { searchConfiguration, deletePhoneData } = useAdvancedActions();

  const formikDuplicatesCheck = useFormik({
    initialValues: {
      dup_check: false,
    },
    validationSchema: yup.object().shape({
      dup_check: yup.boolean(),
    }),
    onSubmit: (values) => {
      updateConfiguration({
        ...data,
        dup_check: values.dup_check === true ? 1 : 0
      });
    },
  });

  const formikSearch = useFormik({
    initialValues: {
      phone: [],
      search: '',
    },
    validationSchema: yup.object().shape({
      search: yup.string().matches(/\+?\d+$/, 'Only numbers is allowed'),
      phone: yup.string(),
    }),
    onSubmit: (values) => {
      if (!values.search) {
        return;
      }

      setIsSubmited(0);

      searchConfiguration(values.search);
    },
  });

  const handleSubmitSearch = (event) => {
    event.preventDefault();

    if (formikSearch.isValid) {
      formikSearch.submitForm();
    }
  };

  const handleDeletePhoneData = (id) => {
    const search = JSON.stringify(JSON.parse(formikSearch.values.search));

    deletePhoneData({ id, search });
  };

  const handleChangeDuplicatesCheck = () => {
    const dupCheck = !formikDuplicatesCheck.values.dup_check;

    formikDuplicatesCheck.setFieldValue('dup_check', dupCheck, false)
      .then(() => {
        formikDuplicatesCheck.submitForm();
      });
  };


  useEffect(() => {
    setIsSubmited(0);
  }, [formikSearch.values.search]);

  useEffect(() => {
    setIsSubmited(1);

    formikSearch.setFieldValue('phone', phone, false);
  }, [phone]);

  useEffect(() => {
    formikDuplicatesCheck.setFieldValue('dup_check', data.dup_check == 1, false);
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper className={classes.card}>
          <div className={classes.header}>
            <div className={classes.inlineContainer}>
              <TooltipedText tooltipText="Notification">
                <Typography variant="h6">Duplicate Check</Typography>
              </TooltipedText>

              <Switch
                className={classes.switch}
                checked={formikDuplicatesCheck.values.dup_check}
                onClick={handleChangeDuplicatesCheck}
              />
            </div>
          </div>

          <FormControl margin="normal">
            <Typography>
              When a new lead is adding through a form submission it is automatically deleted if a duplicate contact has been submitted within the last 24 hours.
            </Typography>
          </FormControl>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper className={classes.card}>
          <div className={classes.inlineContainer}>
            <Typography variant="h6">Delete Individual Records</Typography>
          </div>

          <Typography gutterBottom>Permanently and immediately delete record</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => handleSubmitSearch(event)}
          >
            <TextField
              name="search"
              variant="outlined"
              size="small"
              color="secondary"
              fullWidth
              value={formikSearch.values.search}
              onChange={formikSearch.handleChange}
              onBlur={formikSearch.handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                placeholder: 'Look by phone number',
              }}
              helperText={formikSearch.errors.search}
              error={formikSearch.touched.search && !!formikSearch.errors.search}
            />
          </form>

          {
            // eslint-disable-next-line no-nested-ternary
            (formikSearch.values.search && formikSearch.values.phone && formikSearch.values.phone.length > 0)
              ? (formikSearch.values.phone.map((phoneItem, index) => (
                <div
                  className={phoneItem.Phone1 === '+10000000000' ? classes.rowHidden : classes.row}
                  key={index}
                >
                  <Typography className={classes.phoneWidth} variant="body2">
                    {phoneItem.Phone1 === '+10000000000' ? phoneItem.Phone1 : `${phoneFormatter(phoneItem.Phone1)}`}
                  </Typography>

                  <Typography variant="body2">{phoneItem.inquiryDate}</Typography>

                  <IconButton
                    className={phoneItem.Phone1 === '+10000000000' ? classes.closeButtonHidden : classes.closeButton}
                    onClick={() => handleDeletePhoneData(phoneItem.id)}
                  >
                    <CloseIconAlt />
                  </IconButton>
                </div>
              )))
              : (!formikSearch.values.search || isSubmited === 0
                ? (<></>)
                : (<Typography className={classes.noResults} variant="body2">No resuls found</Typography>)
              )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}
