/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  Link,
  FormControl,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@material-ui/core';


import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import DoneIconAlt from 'src/assets/icons/add.svg';
import CloseIconAlt from 'src/assets/icons/close.svg';
import EditIconAlt from 'src/assets/icons/edit.svg';

import Paper from 'src/components/paper';
import TooltipedText from 'src/components/tooltipedText';
import theme from 'src/themes/defaultTheme';

import { getSettingsData } from 'src/features/me/state/selectors';
import { getConfigurationSearch } from 'src/features/advanceSettings/state/selectors';
import { useAdvancedActions } from 'src/features/advanceSettings/state/hooks';

const useStyles = makeStyles(() => ({
  tooltipButton: {
    cursor: 'default',
  },
  card: {
    height: '100%',
  },
  inlineContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 12,
    '& > *:first-child': {
      marginRight: 10,
    },
  },
  toolbar: {
    minHeight: theme.spacing(3),
  },
  form: {
    paddingTop: 8,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    width: 85,
    marginRight: 10,
    '& input': {
      textAlign: 'center',
    },
  },
  select: {
    width: 130,
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowHidden: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    pointerEvents: 'none',
    opacity: 0.4,
  },
  closeButton: {
    marginRight: -12,
  },
  closeButtonHidden: {
    marginRight: -12,
    opacity: 0,
  },
  phoneWidth: {
    minWidth: 160,
  },
  buttonDone: {
    padding: '8px 8px',
  },
  labelDataRetension: {
    paddingRight: 8,
  },
  conteiner: {
    marginLeft: 0,
  },
  noResults: {
    paddingTop: 8,
  }
}));

export default function DataRetention({ data, isLoading, updateConfiguration }) {
  const classes = useStyles();
  const advancedSettings = useSelector(getSettingsData);
  const [isEdit, setIsEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      data_retention_period: 0,
      days: 1,
    },
    validationSchema: yup.object().shape({
      data_retention_period: yup
        .number().typeError('Must be a number')
        .min(0, 'The value mast be greater or equal to 0')
        .required('Field is required'),
      days: yup.number(),
    }),
    onSubmit: (values) => {
      updateConfiguration({
        ...data,
        data_retention_period: values.data_retention_period * values.days,
      });
    },
  });

  const toggleEditDataRetension = () => {
    setIsEdit(!isEdit);
  };

  const setRetensionPeriodData = (dataRetension) => {
    let days;

    if (dataRetension.data_retention_period > 0 && dataRetension.data_retention_period % 30 === 0) {
      days = 30;
    } else if (dataRetension.data_retention_period > 0 && dataRetension.data_retention_period % 7 === 0) {
      days = 7;
    } else {
      days = 1;
    }

    formik.setFieldValue('data_retention_period', dataRetension.data_retention_period / days, true);
    formik.setFieldValue('days', days, true);
    formik.setTouched({ data_retention_period: true });
  };

  const handleDoneRetentionPeriod = () => {
    toggleEditDataRetension();

    formik.submitForm();
  };

  const handleCancelRetentionPeriod = () => {
    toggleEditDataRetension();

    setRetensionPeriodData(data);
  };

  useEffect(() => {
    setRetensionPeriodData(data);
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper className={classes.card}>
          <div className={classes.inlineContainer}>
            <TooltipedText tooltipText="Select how long your want us to retain your customer data">
              <Typography variant="h6">Data Retention Period</Typography>
            </TooltipedText>
          </div>

          <Typography gutterBottom>Permanently remove all related data after</Typography>

          <FormControl margin="normal" variant="outlined" className={classes.formControl}>
            {
              isEdit === false
                ? (<Grid container spacing={2} className={classes.conteiner}>
                  <p className={classes.labelDataRetension}>
                    {`${formik.values.data_retention_period} ${
                      // eslint-disable-next-line no-nested-ternary
                      formik.values.days == 30 ? 'months' : (formik.values.days == 7 ? 'weeks' : 'days')
                      }`}
                  </p>

                  {
                    advancedSettings.reattempts_limit > 0
                      ? (<Tooltip title="Unable to edit because of not standart quantity of reattempts" arrow placement="right">
                          <div>
                            <IconButton className={classes.tooltipButton}>
                              <EditIconAlt />
                            </IconButton>
                          </div>
                        </Tooltip>)
                      : (<IconButton onClick={toggleEditDataRetension}>
                          <EditIconAlt />
                        </IconButton>)
                  }
                </Grid>)
                : (<Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="data_retention_period"
                      name="data_retention_period"
                      color="secondary"
                      variant="outlined"
                      size="small"
                      value={formik.values.data_retention_period}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.errors.data_retention_period}
                      error={formik.touched.data_retention_period && !!formik.errors.data_retention_period}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="days"
                      name="days"
                      color="secondary"
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      onChange={formik.handleChange}
                      value={formik.values.days}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="1">Days</MenuItem>

                      <MenuItem value="7">Weeks</MenuItem>

                      <MenuItem value="30">Months</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <IconButton
                      className={classes.buttonDone}
                      onClick={handleDoneRetentionPeriod}
                    >
                      <DoneIcon />
                    </IconButton>

                    <IconButton
                      className={classes.buttonDone}
                      onClick={handleCancelRetentionPeriod}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>)
            }
          </FormControl>
        </Paper>
      </Grid>
    </Grid>
  );
}
